import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

const GraphComponent = ({ id }) => {
  const [frequency, setFrequency] = useState("month");
  const [JdcFilter, setJdcFilter] = useState(""); // Default to saving data
  const [JdcProfssion, setJdcProfssion] = useState(""); // Default to saving data
  const [genderFilter, setJdcGender] = useState("");
  const [fromDate, setFromDate] = useState(""); // State for start date
  const [toDate, setToDate] = useState(""); // State for end date
  const [JdcProfession, setJdcProfession] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);
  const [transvalue, settransvalue] = useState([]);
  const [transmonths, settransmonths] = useState([]);

  let token = localStorage.getItem("SuperAgentChromepayToken");
  if (!token) {
    token = localStorage.getItem("ChromePayAdmitToken");
  }

  const JdcCategories = () => {
    axios
      .post(
        `/v1/super-Agent/get-profession-categories `,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let Profession = resp.data?.data;
        console.log({ Profession });
        setJdcProfession(Profession);
      });
  };
  // Fetch data from API
  //   useEffect(() => {
  //     setShowLoader(true)
  //     const fetchData = () => {
  //       let body = {
  //         Type: frequency,
  //         category: JdcFilter,
  //         gender: genderFilter,
  //         fromDate: fromDate,
  //         toDate: toDate,
  //       };
  //       axios
  //         .post(`/v1/super-Agent/get_org_cust_data_graph`, body, {
  //           headers: { Authorization: `Bearer ${token}` }, // Make sure to replace 'token' with actual token.
  //         })
  //         .then((res) => {
  //           const userData = res.data.data;
  //           const savingTransactions = res.data.savingTransactions;
  //           const loanTransactions = res.data.loanTransactions;
  //           const shareTransactions = res.data.shareTransactions;
  //           const storeTransactions = res.data.storeTransactions;

  //           // Data for chart
  //           let transactionData;

  //           // Conditionally show loan or saving data
  //           if (JdcFilter === "loan") {
  //             transactionData = loanTransactions.map((item) => item.totalAmount);
  //             const months = loanTransactions.map((item) => item.value);
  //             setTransMonths(months);
  //           } else if (JdcFilter === "share") {
  //             transactionData = shareTransactions.map((item) => item.totalAmount);
  //             const months = shareTransactions.map((item) => item.value);
  //             setTransMonths(months);
  //           } else if (JdcFilter === "store") {
  //             transactionData = storeTransactions.map((item) => item.totalAmount);
  //             const months = storeTransactions.map((item) => item.value);
  //             setTransMonths(months);
  //           } else {
  //             transactionData = savingTransactions?.map(
  //               (item) => item.totalAmount
  //             );
  //             const months = savingTransactions?.map((item) => item.value);
  //             setTransMonths(months);
  //           }

  //           setTransValue(transactionData);
  //           setShowLoader(false)
  //         });
  //     };

  //     fetchData();
  //   }, [frequency, genderFilter, fromDate, toDate]);

  console.log({ genderFilter });



  const fetchData = () => {
    let body = {
      Type: frequency,
      gender: genderFilter,
      fromDate: fromDate,
      toDate: toDate,
      agentId: id,
      category: JdcProfssion,
    };
    axios
      .post(`/v1/super-Agent/get_admin_cust_data_graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;
        const loanTransactions = res.data.loanTransactions;
        const savingTransactions = res.data.savingTransactions;

        // Extracting data for bar chart (user activity)
        const userActivityCounts = userData.map((item) => item.count);
        const userActivityMonths = userData.map((item) => item.value);

        // Extracting loan and saving data for line charts
        //   const loanAmounts = loanTransactions.map((item) => item.totalAmount);
        //   const savingAmounts = savingTransactions.map(
        //     (item) => item.totalAmount
        //   );
        const months = userActivityMonths; // Ensure all datasets have the same categories (months)

        settransmonths(months);
        settransvalue(userActivityCounts);

        //   setuseractivedatayear(userActivityCounts);
        //   setuseractivedatamonths(months);
        //   setLoanData(loanAmounts);
        //   setSavingData(savingAmounts);
      });
  };




  useEffect(() => {
 

    fetchData();
  }, [frequency, JdcFilter, genderFilter, fromDate, toDate, JdcProfssion]);

  // Handle date input changes
  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  const handleResetDID = () => {
console.log("hlloooo")

    setToDate("");
    setFromDate("");
    setJdcGender("");
    setJdcFilter("");
    setFrequency("month");
    setJdcProfssion("")
  };

  useEffect(() => {
    JdcCategories();
  }, []);

  return (
    <div className="col-lg-12 mt-5">
      <div className="card card-flush h-xl-100">
        <div className="card-header pt-7">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">
              User Onboarded Activity
            </span>
            <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
          </h3>
          <div className="row mb-4 align-items-center">
            {/* Frequency Filter */}
            <div className="col-md-4">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Monthly</option>
                    <option value="year">Yearly</option>
                  </select>
                </div>
              </form>
            </div>

            <div className="col-md-4">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={genderFilter}
                    onChange={(e) => setJdcGender(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    <option selected>Choose any gender option</option>

                    <option key="Male" value="Male">
                      Male
                    </option>

                    <option key="Female" value="Female">
                      Female
                    </option>
                  </select>
                </div>
              </form>
            </div>

            <div className="col-md-4">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={JdcProfssion}
                    onChange={(e) => setJdcProfssion(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    <option selected>Choose any one option</option>

                    {JdcProfession?.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>

            <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
              <div className="row  justify-content-end  flex-grow-1 mb-5 mb-lg-0">
                <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                  <label className="small_label my-label">Start Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                    value={fromDate}
                    onChange={handleFromDateChange}
                  />
                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                </div>

                <div className="col-md-3 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
                  <label className="small_label my-label">End Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                    value={toDate}
                    onChange={handleToDateChange}
                  />
                </div>
                <div className="col-md-2 d-flex justify-content-start align-items-center mb-3 ps-1 input-container mb-lg-0">
                  <button
                    type="button"
                    onClick={() => handleResetDID()}
                    className="btn btn-light btn-active-light-primary"
                    id="kt_advanced_search_button_1"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-auto">
          <Chart
            type="area"
            width={"100%"}
            height={400}
            series={[
              {
                name: "User",
                data: transvalue,
              },
            ]}
            options={{
              plotOptions: {
                bar: {
                  borderRadius: 2,
                  columnWidth: 50,
                },
              },
              markers: {
                size: 0,
              },
              stroke: {
                width: 3,
                curve: "smooth",
              },

              colors: ["#3c7f8c"],
              theme: { mode: "light" },

              xaxis: {
                tickPlacement: "on",
                categories: transmonths,
                title: {
                  style: { color: "#f90000", fontSize: 0 },
                },
              },

              yaxis: {
                labels: {
                  formatter: (val) => {
                    return `${val}`;
                  },
                },
                //
              },

              legend: {
                show: true,
                position: "right",
              },

              dataLabels: {
                formatter: (val) => {
                  return `${val}`;
                },
                style: {
                  colors: ["#3c7f8c"],
                  fontSize: 15,
                },
              },
            }}
          ></Chart>
        </div>
      </div>
    </div>
  );
};

export default GraphComponent;
